import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { selectCommon, setUserData } from '../common/services/common.service';
import { useDispatch, useSelector } from 'react-redux';
import { setUserDataToLocalStore } from 'src/auth/RBAC/utils';
import { goToRoute } from 'src/auth/login/Utils';
import { useAuth } from 'src/providers/AuthguardContext';

const ReceiveTokenModule = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { userData } = useSelector(selectCommon);
  const { login } = useAuth();

  useEffect(() => {
    // Listen for postMessage events
    window.addEventListener('message', handleMessage);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [dispatch]);

  const handleMessage = async (event) => {
    let handleMessageCallFlag = true;
    console.log('origin',event.origin)
    console.log('env origin',process.env.REACT_APP_FUSION_ONE_DOMAIN_URL)
    if (
      event.origin === process.env.REACT_APP_FUSION_ONE_DOMAIN_URL &&
      handleMessageCallFlag
    ) {
      console.log('condition passed')
      const { accessToken, userId, onboardingStatus } = event?.data?.data;
      handleMessageCallFlag = false;
      const payload = {
        token: accessToken,
        onboardingStatus: onboardingStatus,
        userUid: userId
      };

      const userData = await setUserDataToLocalStore(payload); // Set each variable in local storage
      await dispatch(setUserData(userData));
    }
  };

  //useEffect
  useEffect(() => {
    // if (!showLogin && Object.keys(userData).length)
    // commented the above code because it blocks user to login in multiple window
    if (Object.keys(userData).length) {
      if (userData?.accessToken) login(userData);
      const route = goToRoute(userData);
      const path = '/' + route;
      navigate(path);
    }
  }, [userData, login, navigate]);

  return (
    <Box className="spinnerWrapper flex-basic-center mt-100 mb-100 ">
      <Box className="spinnerLoading "></Box>
    </Box>
  );
};

export default ReceiveTokenModule;
